import API from './utils/request';
import { isServer, getcloudUrl } from '@utils/helper';
// 如果是在服务端调用接口,那么需要根据不同的环境设置不同的host
const baseUrl = isServer() ? process.env.REACT_APP_BASE_API : '';
const cloudUrl = process.env.REACT_APP_CLOUD_API;

const storePrefixPath = '/services/store/api';
const productsPrefixPath = '/services/products/api';
const thirdPartyUrl = '/services/store/api/thirdparty';
const appsPrefixPath = '/services/apps/api';

/**
 * 提交登录
 * @param {} formData
 * @returns {Promise(<object|null>)} - 返回申请结果
 */
export const login = (formData) =>
  API.post(`/auth/login`, formData).then(({ data }) => data || null);

/**
 * 用户是否登录
 */
export const checkAuthenticated = (formData) =>
  API.get(`${cloudUrl}/basic-auth/api/v1/user/login/check`, formData).then(
    ({ data }) => data || null
  );

/**
 *用户信息
 *  @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const getCurrentInfo = (formData) =>
  API.get(
    `${cloudUrl}/cloud-account/api/v1/user/getInfoByToken`,
    formData
  ).then(({ data }) => data || null);

/**
 * 获取APP类型分类
 * @param {string} parentClassifyId
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const queryDictListByTypeCode = () =>
  API.get(
    `${baseUrl}${storePrefixPath}/appShelf/app_classify/queryDictListByTypeCode`
  ).then(({ data }) => data || null);

/**
 * 优惠券适用产品列表
 */
export const getCouponSuitProduct = (formData) =>
  API.post(`${productsPrefixPath}/coupon-rule/app/list`, formData).then(
    ({ data }) => data || null
  );

/**
 * 获取首页轮播图
 * @param
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const queryBannerInfoList = (formData) => {
  console.log(cloudUrl, 'cloudUrl');
  return API.post(`${cloudUrl}/basic-common/basic/banner/list`, formData).then(
    ({ data }) => data || null
  );
};

/**
 * 调用应用商店列表获取上架应用信息
 * @param {string} appClassify
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const queryAppShelfInfoList = ({
  appClassify = '',
  pageNo,
  pageSize,
}) => {
  return API.post(
    `${baseUrl}${storePrefixPath}/appShelf/queryAppShelfInfoList`,
    {
      appClassify,
      pageNo,
      pageSize,
    }
  ).then(({ data }) => data || null);
};

/**
 * 根据applicationID查询上架应用详情信息
 * @param {number} applicationID
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const queryDetailInfoById = ({ applicationID }) => {
  return API.get(
    `${baseUrl}${storePrefixPath}/appShelf/${applicationID}/queryDetailInfoById`,
    {
      params: {
        applicationId: applicationID,
      },
    }
  ).then(({ data }) => data || null);
};

/**
 * 关注开发者
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const followDeveloper = ({ developerId }) =>
  API.post(`${storePrefixPath}/followDeveloper/jwt/follow/${developerId}`).then(
    ({ data }) => data || null
  );

/**
 * 取消关注开发者
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const cancelFollowDev = ({ developerId }) =>
  API.delete(
    `${storePrefixPath}/followDeveloper/jwt/unFollow/${developerId}`
  ).then(({ data }) => data || null);

/**
 * 收藏app
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const collectApp = ({ applicationID }) =>
  API.post(
    `${storePrefixPath}/appCollection/jwt/collectApp/${applicationID}`
  ).then(({ data }) => data || null);

/**
 * 取消收藏app
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const cancelCollect = ({ applicationID }) =>
  API.delete(
    `${storePrefixPath}/appCollection/jwt/cancelCollect/${applicationID}`
  ).then(({ data }) => data || null);

/**
 * 获取app状态 包括 是否可购买 是否已经收藏
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const getAppPurchaseStatus = ({ applicationID }) =>
  API.get(
    `${baseUrl}${storePrefixPath}/appOrder/jwt/getUserAppStatus/${applicationID}`
  ).then(({ data }) => data || null);

/**
 * 保存浏览记录
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const saveHistory = ({ applicationID }) =>
  API.post(
    `${baseUrl}${storePrefixPath}/browseHistory/jwt/saveHistory/${applicationID}`
  ).then(({ data }) => data || null);

/**
 * 评分及评价
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const getAppEvaluateBaseInfo = ({ applicationID }) =>
  API.get(
    `${baseUrl}${storePrefixPath}/appShelf/getAppEvaluateBaseInfo/${applicationID}`
  ).then(({ data }) => data || null);

/**
 * 购买规格
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const getAppPayModeInfo = ({ applicationID, versionID }) =>
  API.get(
    `${baseUrl}${storePrefixPath}/appShelf/getAppPayModeInfo/${applicationID}/${versionID}`
  ).then(({ data }) => data || null);

/**
 * 根据查询开发者其他应用
 * @param {number} applicationID
 * @param {string} queryType
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const queryAppDetailOtherAppInfo = ({
  applicationID,
  queryType = 'developer',
}) =>
  API.get(
    `${baseUrl}${storePrefixPath}/appShelf/${applicationID}/queryAppDetailInfo?queryType=developer&applicationId=${applicationID}`
  ).then(({ data }) => data || null);

/**
 * 获取app选购信息
 * @param {number} applicationID
 * @param {number} versionID
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const getAppBuyInfo = ({ applicationID, versionID }) =>
  API.get(
    `${storePrefixPath}/appOrder/jwt/getAppBuyInfo/${applicationID}/${versionID}`
  ).then(({ data }) => data || null);

/**
 * 查询用户使用中的实例信息
 */
export const getInstanceUsing = (params) =>
  API.post(`${productsPrefixPath}/instance/using`, params).then(
    ({ data }) => data || null
  );

/**
 * 通过实例编码查询使用中的实例信息
 */
export const getInstanceById = (instanceId) =>
  API.get(`${productsPrefixPath}/instance/using/${instanceId}/instanceId`).then(
    ({ data }) => data || null
  );

/**
 * 获取当前用户对应产品可使用优惠券列表
 */
export const getCouponList = (productId, productType) =>
  API.get(
    `${productsPrefixPath}/coupon/available?productId=${productId}&productType=${productType}`
  ).then(({ data }) => data || null);

/**
 * 查询是否有可领取券
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const checkCoupon = (productId) =>
  API.get(
    `${productsPrefixPath}/coupon/can-get/check?productId=${productId}&productType=2`
  ).then(({ data }) => data || null);

/**
 * 获取可领取券列表
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const getCouponCenterList = (formData) =>
  API.post(`${productsPrefixPath}/coupon/can-get/page`, formData).then(
    ({ data }) => data || null
  );

/**
 * 兑换优惠券
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const exchangeCoupon = (formData) =>
  API.post(`${productsPrefixPath}/coupon/exchange`, formData).then(
    ({ data }) => data || null
  );

/**
 * 领取优惠券
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const receiveCoupon = (formData) =>
  API.post(`${productsPrefixPath}/coupon/draw`, formData).then(
    ({ data }) => data || null
  );

/**
 * 获取工业软件banner
 */
export const getBanners = () =>
  API.get(`${baseUrl}${thirdPartyUrl}/application/slogan/list`).then(
    ({ data }) => data || null
  );

/**
 * 获取工业软件app列表
 */
export const getAPPByType = (formData) =>
  API.post(`${baseUrl}${thirdPartyUrl}/application/list`, formData).then(
    ({ data }) => data || null
  );

/**
 * 获取工业软件app类型分类
 * @param {string} parentClassifyId
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const queryDictList = () =>
  API.get(`${baseUrl}${thirdPartyUrl}/application/classify/list`).then(
    ({ data }) => data || null
  );

/**
 *  分页获取某种类型的APP
 */
export const getAppsPageListByType = (formData) =>
  API.post(`${baseUrl}${thirdPartyUrl}/application/page/list`, formData).then(
    ({ data }) => data || null
  );

/**
 * 获取APP详情信息
 */
export const getAppDetail = ({ appId }) =>
  API.get(`${baseUrl}${thirdPartyUrl}/application/detail/${appId}`).then(
    ({ data }) => data || null
  );

/**
 * 搜索APP
 */
export const getSearchResult = (formData) =>
  API.post(
    `${baseUrl}${thirdPartyUrl}/application/keysearch/list`,
    formData
  ).then(({ data }) => data || null);

/* 提交订单 生成订单号
 * @param {object} formData
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const commitAppOrder = (formData = {}) =>
  API.post(`${storePrefixPath}/appOrder/jwt/commitAppOrder`, {
    ...formData,
  }).then(({ data }) => data || null);

/**
 * 通过订单号查询订单详情信息
 * @param {object} formData
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const getOrderInfo = (formData = {}) =>
  API.get(
    `${baseUrl}${storePrefixPath}/appOrder/jwt/getOrderInfo/${formData.orderNo}`
  ).then(({ data }) => data || null);

/**
 * 根据搜索关键词查询搜索结果
 * @param {string} queryWord
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const getQueryInfo = (formData) => {
  return API.post(
    `${baseUrl}${storePrefixPath}/appShelf/queryAppListByKey`,
    formData
  ).then(({ data }) => data || null);
};

/**
 * 根据应用标签查询搜索结果
 * @param {string} queryTag
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const getQueryInfoByTag = (formData) => {
  return API.post(
    `${baseUrl}${storePrefixPath}/appShelf/queryAppListByTag`,
    formData
  ).then(({ data }) => data || null);
};

/**
 * 查询关注状态
 * @returns {Promise(<object|null>)} - 返回提交结果信息
 */
export const getFollowStatus = ({ accountId }) =>
  API.get(`${storePrefixPath}/followDeveloper/jwt/isFollow/${accountId}`).then(
    ({ data }) => data || null
  );

/**
 * 开发者主页信息
 * @returns {Promise(<object|null>)} - 返回列表
 */
export const getDeveloperDetailInfo = ({ accountId }) => {
  return API.get(
    `${baseUrl}${storePrefixPath}/account/developerDetailInfo/${accountId}`
  ).then(({ data }) => data || null);
};

/**
 * 解决方案分类字典
 */
export const getSolutionClassify = () =>
  API.get(`${baseUrl}${storePrefixPath}/solution/classify/list`).then(
    ({ data }) => data || null
  );

/**
 *  关键字搜索解决方案列表
 */
export const getSolutionsByFilter = (formData) =>
  API.post(
    `${baseUrl}${storePrefixPath}/solution/keysearch/list`,
    formData
  ).then(({ data }) => data || null);

/**
 *  查询解决方案详情
 */
export const getSolutionDetail = (param) =>
  API.get(`${baseUrl}${storePrefixPath}/solution/detail/${param}`).then(
    ({ data }) => data || null
  );

/**
 *  解决方案咨询提交
 */
export const postSolutionForm = (formData) =>
  API.post(
    `${baseUrl}${storePrefixPath}/solution/consult/commit`,
    formData
  ).then(({ data }) => data || null);

/**
 *  查询行业数据字典
 */
export const getDevAppIndustries = () =>
  API.get(
    `${baseUrl}${storePrefixPath}/dict/consultant_business/queryDictListByTypeCode`
  ).then(({ data }) => data || null);

/**
 *  查询职位数据字典
 */
export const getPositions = () =>
  API.get(
    `${baseUrl}${storePrefixPath}/dict/consultant_position/queryDictListByTypeCode`
  ).then(({ data }) => data || null);

/**
 *  查询企业规模数据字典
 */
export const getCorporateSizes = () =>
  API.get(
    `${baseUrl}${storePrefixPath}/dict/consultant_enterprise_scale/queryDictListByTypeCode`
  ).then(({ data }) => data || null);

/**
 *  检测APP是否在该实例上安装过
 * @param {*} applicationId
 *  @param {*} instanceId
 *
 * @returns
 */
export const checkAPPInstalled = ({ applicationId, instanceId }) =>
  API.get(
    `${storePrefixPath}/appOrder/jwt/instance/check?applicationId=${applicationId}&instanceId=${instanceId}`
  ).then(({ data }) => data || null);

/**
 *  查询企业规模数据字典
 */
export const getDeveloperAPPList = (formData) =>
  API.post(
    `${baseUrl}${appsPrefixPath}/account/developerDetailInfo/byLogin/apps/page`,
    formData
  ).then(({ data }) => data || null);

/**
 *  关键字搜索开发者
 */
export const queryDevelopers = ({ searchName }) =>
  API.get(
    `${cloudUrl}/cloud-account/user/getDevelopers?searchName=${searchName}`
  ).then(({ data }) => data || null);
