import axios from 'axios';
import { message } from 'antd';
import Cookies from 'js-cookie';
import FetchError from './FetchError';
import { goLogin } from './helper';
import JSONBig from 'json-bigint';

const handleLogin = (url) => {
  // 服务端渲染跳过
  if (typeof window === 'undefined') {
    return;
  }
  // 这个接口特殊处理 401 不需要跳转登录页
  if (url.indexOf('/services/store/api/appOrder/jwt/getUserAppStatus') >= 0) {
    return;
  }

  goLogin();
};

const setXSaToken = (config) => {
  const satoken = Cookies.get('lzy_session_token');
  if (satoken) {
    config.headers['X-Sa-Token'] = satoken;
  }
};

const dealStatus = (res) => {
  if (!res) return;

  if (res.status !== 200) {
    const err = new FetchError(res.url, {
      traceId: Math.random() * 10000, // create your application traceId
      userId: 26,
      message: `${
        typeof window !== 'undefined' ? 'Client' : 'Server'
      } fetch error - ${res.status}`,
    });
  }
  return res;
};

/**
 * 重要！！！本地启用http-proxy-middleware代理的时候API_DOMAIN 请重置为空
 */
export let API_DOMAIN = ''; //"http://192.168.8.199:8080/";
// export const commonurl = "/services/"; //services是所有api的公共url
export const commonurl = ''; //services是所有api的公共url
// const NODE_ENV = process.env.NODE_ENV;
// if(NODE_ENV==="production")API_DOMAIN="http://192.168.8.199:8080";
const instance = axios.create({
  baseURL: `${API_DOMAIN}${commonurl}`,
  timeout: 1000 * 60 * 3,
  headers: {
    'Accept-Encoding': 'gzip',
    'X-Token-Type': 1,
  },
  // 解决数字精度丢失
  transformResponse: [
    function (data) {
      // 对 data 进行任意转换处理
      try {
        return JSONBig.parse(data);
      } catch (err) {
        return data;
      }
    },
  ],
});

/**
 * 添加请求拦截器
 */
instance.interceptors.request.use(
  function (config) {
    console.log('configconfigconfigconfig:', config.url);
    setXSaToken(config);
    return config;
  },
  function (error) {
    // 对请求错误做些什么

    return Promise.reject(error);
  }
);

/**
 * 添加响应拦截器
 */
instance.interceptors.response.use(
  function (response) {
    if (response && response.data) {
      if (
        !(
          response.data.success ||
          response.data.succeeded ||
          response.data.code === 0
        )
      ) {
        response.data.message && message.error(response.data.message);
      }
    }

    return response || null;
  },
  function (e) {
    dealStatus(e.response);

    // 对响应错误做点什么
    if (e.response) {
      const {
        response: {
          status,
          data: { message: messageInfo },
          config: { url },
        } = {},
      } = e;

      // console.log(e, ' ee-------------------eeeeeee------------ee');

      // console.log(e.response, ' ee-------------------eeeeeee------------ee');

      if (status === 401) {
        handleLogin(url);
      } else if (status >= 400 && status < 500) {
        message.error(messageInfo);
      } else if (status >= 500) {
        if (typeof window !== 'undefined') {
          window.location.href = '/exception/500';
        }
      } else {
        message.error('系统异常');
      }

      return e.response;
    }
  }
);

/**
 * 生成用于取消请求的cancel与token
 * @see https://github.com/axios/axios#cancellation
 */
export const makeCancelTokenSource = () => axios.CancelToken.source();

export default instance;
